<template>
  <div>
    <v-bottom-sheet
        right
        app
        v-model="navigation"
        disable-route-watcher
        persistent
        bottom
        width="60%"
    >
      <v-card flat min-height="80vh" style="position: relative">
        <div class="close">
          <v-btn fab depressed small @click.stop="navigation=false" color="secondary" dark>
            <v-icon dark>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-toolbar color="grey darken-3" dark elevation="1">
          <v-btn dark color="secondary" depressed :disabled="!selectedItem" @click.stop="chooseByRow">Обрати</v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text style="overflow: hidden" class="px-0">
          <RemovalItemView @selectedItemRow="selectedRow" :selectable="true" :model="navigation"/>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <MainModal
        :main="{ component: 'RemovalItem', title: 'Статті зняття' }"
        :button="{
                    icon: false,
                    buttonHidden: true,
                  }"
        :item="{}"
        :modal="modal"
        @updateItemModal="closeModal"/>
    <v-autocomplete
        v-model="model"
        :color="color"
        :items="getRemovalItemSelect"
        :loading="isLoading"
        :search-input.sync="search"
        :dense="dense"
        :filled="filled"
        :chips="chips"
        :small-chips="chips"
        :multiple="multiple"
        :disabled="disabled"
        :required="required"
        no-data-text="Не знайдено жодної статті зняття"
        :hide-details="true"
        :label="label"
        placeholder="почніть вводити текст для пошуку"
        @change="changeValue"
        :solo="solo"
        :style="style_"
        :menu-props="menuProps"
        :clearable="clearable"
        :rules="rules()"
        :flat="tile"
        return-object
    >
      <template v-slot:selection="data" v-if="chips">
        <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            @click="data.select"
            @click:close="remove(data.item)"
            class="my-1"
            color="white"
        >
          <v-icon color="secondary" class="mr-3">
            mdi-bank-outline
          </v-icon>
          <span class="grey--text text--darken-3">{{ data.item.text }}</span>
        </v-chip>
      </template>
      <template v-slot:item="data">
        <template>
          <v-list-item-content style="padding: 6px 12px !important;" class="custom-list-item">
            <v-list-item-subtitle v-html="data.item.text" class="grey--text text--darken-2"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
      <template v-slot:prepend-item>
        <div class="mb-5 prepend-list-item">
          <div class="prepend-list-item-buttons">
            <div class="a-list">
              <a href="" @click.prevent="openList">Показати усі елементи у списку</a>
            </div>
            <div class="a-button">
              <v-btn @click="openModal" small class="grey darken-2" depressed>
                <v-icon color="white">mdi-plus</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import {getCityIcon} from "@/utils/icons";
import {mapGetters} from "vuex";
import {SELECT_REMOVAL_ITEM} from "@/store/actions/removal";

export default {
  name: "RemovalItem",
  props: {
    filled: {
      type: Boolean,
      default: true
    },
    autosync: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    chips: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    solo: {
      type: Boolean,
      default: false
    },
    style_: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'Стаття зняття'
    },
    clearable: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    denseList: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    row_num: {
      type: Number,
      default: 0
    },
    building: {
      type: Boolean,
      default: false
    },
    tile: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'grey lighten-1'
    }
  },
  components: {
    MainModal: () => import('@/components/modal/MainModal'),
    RemovalItemView: () => import('@/components/RemovalItemView')
  },
  computed: {
    ...mapGetters({
      getRemovalItemSelect: 'getRemovalItemSelect'
    }),
    menuProps() {
      return {
        closeOnContentClick: this.modal,
        color: 'error'
      }
    }
  },
  data: () => ({
    entries: [],
    isLoading: false,
    model: null,
    search: null,
    modal: false,
    navigation: false,
    selectedItem: null
  }),
  methods: {
    changeValue() {
      let payload
      if (this.multiple) {
        payload = []
        if (this.model) {
          this.model.forEach(i => {
            payload.push(i)
          })
        }
      } else {
        payload = {row_num: this.row_num}
        if (this.model) {
          Object.assign(payload, this.model)
        }
      }
      this.$emit('autocompleteChange', payload)
    },
    rules() {
      if (this.required) {
        return [v => !!v || 'Це поле є обов’язковим']
      } else {
        return [true]
      }
    },
    chooseByRow() {
      // this.fetch(true)
      this.navigation = false
      this.model = this.getRemovalItemSelect.find(item => item.value === this.selectedItem.id)
      this.$emit('autocompleteChange', this.model)
    },
    selectedRow(payload) {
      if (payload === undefined) {
        this.selectedItem = null
      } else {
        this.selectedItem = Object.assign({}, payload)
      }
    },
    openList() {
      this.navigation = true
    },
    openModal() {
      this.modal = true
    },
    closeModal() {
      this.modal = false
      this.fetch()
    },
    fetch() {
      if (!this.$store.state.removal.select_loaded) {
        this.$store.dispatch(SELECT_REMOVAL_ITEM)
            .then(finish => {
              if (finish) {
                const obj = this.getRemovalItemSelect.find(item => item.value === this.value)
                this.multiple ? this.model.push(obj) : this.model = obj
              }
            })
      } else {
        const obj = this.getRemovalItemSelect.find(item => item.value === this.value)
        this.multiple ? this.model.push(obj) : this.model = obj
      }
    },
    remove(item) {
      const fElement = this.model.find(i => i.value === item.value)
      const index = this.model.indexOf(fElement)
      if (index >= 0) this.model.splice(index, 1)
    },
    getCityIcon
  },
  watch: {
    value: {
      handler(payload) {
        if (payload) {
          this.model = this.getRemovalItemSelect.find(item => item.value === payload)
        } else {
          this.model = this.multiple ? [] : null
        }
      }
    }
  },
  created() {
    this.fetch()
  }
}
</script>

<style scoped lang="scss">
.main-height:deep(.v-input__control) {
  min-height: 34px !important;
}

.main-height:deep(.v-input__control .v-input__slot) {
  min-height: 34px !important;
}

.close {
  position: absolute;
  right: -8px;
  top: -9px;
  z-index: 1;
}

.table-input {
  width: 84%;
  display: block;
  outline: none;

  //&:focus {
  //  border-color: var(--v-success-base);
  //  font-weight: 500;
  //}
  &:deep(input) {
    font-size: .84rem !important;
    padding: 3px 4px 2px 0 !important;
    transition: all .2s ease-in-out !important;
    line-height: 0 !important;
  }

  &:deep(.v-input) {
    margin-top: 0 !important;

    .v-input__slot:before {
      border: none !important;
    }

    .v-input__slot:after {
      border: none !important;
    }

    .v-input__append-inner {
      display: none !important;
    }
  }
}

.custom-append-list {
  display: flex;
  padding: 0;
  text-transform: uppercase;

  a {
    display: block;
    width: 50%;
    text-align: center;
    padding: 8px;
    font-weight: 500;
    font-size: 0.9rem;

    &:nth-child(1) {
      background-color: #585858;
      color: white;
      text-decoration: none;
    }

    &:nth-child(2) {
      background-color: #f18a0d;
      color: white;
      text-decoration: none;
    }
  }
}

//div:has(.custom-list-item) {
//  background-color: #d17000;
//}
</style>